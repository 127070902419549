<template>
  <div class="partner" id="lshPartner">
    <div class="lsh-partner">
      <p class="lsh-title">
        合作伙伴
      </p>
      <div class="partner-main">
        <div class="partner-img-first">
          <img v-lazy="images.url1" alt="" style="width:50%;height:300px;">
          <img v-lazy="images.url2" alt="" style="width:50%;height:300px;">
          <img v-lazy="images.url3" alt="" style="width:50%;height:300px;">
          <img v-lazy="images.url4" alt="" style="width:50%;height:300px;">
          <img v-lazy="images.url5" alt="" style="width:50%;height:300px;">
          <img v-lazy="images.url6" alt="" style="width:50%;height:300px;">
          <img v-lazy="images.url7" alt="" style="width:50%;height:300px;">
          <img v-lazy="images.url8" alt="" style="width:50%;height:300px;">
        </div>
        <div class="partner-img-second">

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data(){
      return {
        images: {
          url1: require('@/assets/img/partner1.jpg'),
          url2: require('@/assets/img/partner2.jpg'),
          url3: require('@/assets/img/partner3.jpg'),
          url4: require('@/assets/img/partner4.jpg'),
          url5: require('@/assets/img/partner5.jpg'),
          url6: require('@/assets/img/partner6.jpg'),
          url7: require('@/assets/img/partner7.jpg'),
          url8: require('@/assets/img/partner8.jpg')
        }
      }
    }
  }
</script>

<style lang="less">
  .partner{
    .lsh-partner{
      position: relative;
      z-index: 50;
      width: 1300px;
      margin: 0 auto;
      padding: 0 0 0;
      .lsh-title{
          font-size: 48px;
          text-align: center;
          color: #333;
          line-height: 48px;
          margin-top: 30px;
          margin-bottom: 20px;
      }
      .partner-main{
        background-color: #f0f0f0;
        .partner-img-first{
          display: inline-block;
        }

      }
      .clearfix{
        *zoom: 1;
      }
      .clearfix:after{
        content: "";
        display: block;
        height: 0;
        clear:both;
        visibility: hidden;
      }
    }

 }
</style>
